<template>
  <div>
    <el-card>
      <nav class="out">
        <nav class="input" style="width: 150px">
          <el-select
            v-model="search.status"
            placeholder="审核状态"
            size="medium"
          >
            <el-option label="全部" :value="-1" />
            <el-option label="待审核" :value="0" />
            <el-option label="审核通过" :value="1" />
          </el-select>
        </nav>

        <el-button size="small" @click="reset">重置</el-button>
        <el-button type="primary" size="small" @click="subimt">{{
          '搜索'
        }}</el-button>
      </nav>
    </el-card>
    <el-card>
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
      >
        <el-table-column label="ID" prop="id"> </el-table-column>
        <el-table-column label="内容" prop="content"></el-table-column>
        <el-table-column label="内容" prop="content">
          <template slot-scope="{ row }">
            <el-tag
              size="small"
              v-if="Ftag(row.status)"
              :type="row.status == 1 ? '' : 'info'"
            >
              {{ Ftag(row.status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
              @click="audit(row, 1)"
              type="text"
              icon="el-icon-edit"
              size="mini"
              class="red"
              >审核通过</el-button
            >
            <br />
            <el-button
              type="text"
              icon="el-icon-edit"
              style="color:red"
              size="mini"
              @click="audit(row, 3)"
              class="red"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="search.page"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="search.pageSize"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </div>
</template>

<script>
import list from '/src/mixin/list.js'
export default {
  mixins: [list],
  data() {
    return {
      search: {
        name: '',
        page: 1,
        pageSize: 10,
        status: 0
      },
      timOut: null
    }
  },
  created() {
    this.getlist()
    // clearInterval(this.timOut)
    // this.timOut = setInterval(() => {
    //   this.getlist()
    // }, 3000)
  },
  destroyed() {
    // clearInterval(this.timOut)
  },
  methods: {
    add() {},
    reset() {
      this.search = this.$options.data.call(this).search
      this.getlist()
    },
    async getlist() {
      const { data: res } = await this.$http.get('/admin/Gicc/getCheckList', {
        params: {
          ...this.search
        }
      })
      //   console.log(res)

      this.list = res.data.list
      this.total = res.data.totalCount
      //   if (this.search.page != 1 && res.data.list.length == 0) {
      //     this.getlist()
      // this.search.page = 1
      //   }
    },
    Ftag(val) {
      if (val == 0) {
        return '待审核'
      }
      if (val == 1) {
        return '审核通过'
      }
      return
    },
    subimt() {
      this.search.page = 1
      this.getlist()
    },
    async audit(row, val) {
      const { data: res } = await this.$http.post('/admin/Gicc/check', {
        ...row,
        status: val
      })
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.getlist()
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
